.button {
  &_link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    min-width: 150px;
    max-width: fit-content;
    padding: 0.75rem 1.5rem;
    color: #ffffff;
    border: 1px solid orange;
    background-color: orange;
    border-radius: 12px;
    text-decoration: none;
    &:hover {
      color: orange;
      background-color: #ffffff;
    }
  }
  &_img {
    max-height: 24px;
    width: auto;
    margin-left: 0.5rem;
  }
}
