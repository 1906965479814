.description{
    border-radius: 12px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
    margin-bottom: 2rem;
}
.image{
    width: 30%;
    border-radius: 12px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
    margin-bottom: 1rem;
}
.subTitle{
    font-size: 1.125rem;
    color: #003380;
    margin-bottom: 1rem;
}
p{
    display: inline-block;
    text-overflow: ellipsis !important;
    font-size: 1.1rem;
    color: #737373;
}
@media (max-width: 1024px) {
    .image{
        width: 100%;
    }
}
