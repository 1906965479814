.img{
    height: 80px;
}
.NavLinkCustom {
    height: 100%;
    color: white;
    font-size: 1.3rem;
    padding: 0 0.75rem;
    a{
        padding: 0 0.75rem;
        color: white;
    }
    .dropdownitem {
        color: #003380!important;
        padding: 0.3rem 0.75rem;
        font-weight: 600;
    }
}
@media (max-width: 768px) {
    .img{
        height: 60px;
    }
    .NavLinkCustom {
        display: block;
        padding-bottom: 1rem;
        a{
            padding: 0 0.75rem 0 0;
        }
        .dropdownitem{
            padding-bottom: 1rem;
        }
    }
}
