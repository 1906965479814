.sidebar {
  display: block;
  background-color: #003380;
  min-width: auto;
  max-width: auto;
  &_title {
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 40px;
      height: 4px;
      border-radius: 2px;
      background-color: white;
      bottom: -5px;
      left: 0;
    }
  }
  &_links {
    display: flex;
    flex-direction: column;
    &_item {
      position: relative;
      text-decoration: none;
      color: white;
      font-size: 1em;
      font-weight: 500;
      padding: 0.25rem 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 50px;
        height: 5px;
        border-radius: 2px;
        background-color: white;
        left: -100%;
      }
      &:hover::after {
        transition: all 0.2s ease-in-out;
        left: 0px;
      }
      &:hover {
        color: white;
      }
      &.sidebar_links_item_active {
        &::after {
          left: 0px;
        }
      }
    }
  }
}
