.cardsContainer {
    margin-bottom: 5rem;
    &_data {
      position: relative;
      > svg {
        position: absolute;
        color: white;
        width: auto;
        height: 30px;
        top: 1rem;
        left: 1rem;
      }
    }
  }
  