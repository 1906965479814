.container{
    display: flex;
    padding: 0px !important;
}
.Card{
    border-radius: 12px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
}
.maintimeline {
    position: relative;
}
.maintimeline::after {
    content: "";
    position: absolute;
    width: 6px;
    background-color: #005ce6;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    border-radius: 12px;
}
.timeline {
    position: relative;
    background-color: inherit;
    width: 50%;
}
.timeline::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    right: -13px;
    top: 15px;
    background-color: #005ce6;
    border: 5px solid #003380;
    border-radius: 50%;
    z-index: 1;
}
.left {
    position: relative;
    padding: 0px 40px 20px 0px;
    left: 0;
    text-align: end;
}
.right {
    position: relative;
    padding: 0px 0px 20px 40px;
    left: 100%;
}
.subTitle{
    font-size: 1.75rem;
    color: #003380;
    margin-bottom: 1rem;
}
p{
    font-size: 1.1rem;
    text-align: justify;
}
@media (max-width: 1024px) {
    .container{
        flex-direction: column;
    }
}
@media (max-width: 768px) {
    .container{
        flex-direction: column;
    }
}
