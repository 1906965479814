.card {
  display: inline-block;
  font-family: 'Montserrat';
  color: #b1b1b1;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 12px;
  width: 252px !important;
  margin: 1.2rem;
  background: #FFFFFF;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.imgContainer {
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 16px;

  .img {
    width: 100%;
  }
}

%ellipsis {
  display: inline-block;
  text-overflow: ellipsis !important;
  width: 100%;
  line-height: 18px;
  font-size: 1.0rem;
}

.title {
  height: 54px;
  color: #003380;
  margin-bottom: 0.5rem;
}

.description {
  height: 36px;
  margin-bottom: 2rem;
  font-size: 14px;
}

.downloadButton {
  color: white;
  width: 100%;
  margin-top: 20px;
  font-size: 0.8rem;
  font-weight: 600;
}