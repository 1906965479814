.guide_title {
  color: #000000;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.guide_subtitle {
  color: #000000;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
}
.text {
  color: #393939;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 2rem;
}
.logo_airlive_guide {
  height: 80px;
  width: auto;
}
.grid_content_center {
  display: grid;
  justify-items: center;
}
.gap {
  &_1 {
    gap: 1rem;
  }
  &_2 {
    gap: 2rem;
  }
}
.img_guide {
  max-height: 300px;
  width: 100%;
  margin: 0 auto;
  display: block;
}
.list_guide li {
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: #000000;
}
.text_style {
  font-size: 1.3rem;
  color: #000000;
  margin-bottom: 0.75rem;
  line-height: 2rem;
}
.margin_bottom {
  margin-bottom: 1rem;
}
.step {
  &_title {
    font-weight: 600;
    @extend.text_style;
    @extend.margin_bottom;
  }
  &_text {
    @extend.text_style;
    @extend.margin_bottom;
  }
  &_img{
    width: 100%;
    height: auto;
    margin-bottom: 1.5rem;
  }
}
ol {
  padding-left: 0;
}