.listItem{
    list-style: none;
}
.image{
    width: 75%;
}
.video{
    border-radius: 12px !important;
    overflow: hidden;
}
