.image {
  width: 100%;
  border-radius: 12px;
}
table {
  border-collapse: collapse;
  width: 100%;
  background-color: white;
  border-spacing: 0;
  th,
  td {
    padding: 10px;
    text-align: center;
    border: 2px solid #e6e6e6;
  }
  th {
    background-color: #e6e6e6;
    color: black;
  }
}
