p{
  display: inline-block;
  text-overflow: ellipsis !important;
  font-size: 1.1rem;
  color: #737373;
}
.description{
  border-radius: 12px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
  margin: 2rem 0;
  padding: 1rem;
}
.subTitle{
  font-size: 1.125rem;
  color: #003380;
  margin-bottom: 1rem;
}
.img_support {
  border-radius: 12px;
  max-height: 500px;
  width: 100%;
  display: flex;
  margin: auto;
}
.banner {
  background-image: url("../../../../../assets/images/banners/banner_05.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
