.LeftList,
.RightList{
    border: 1px solid #ccc;
    border-radius: 12px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
    margin: 0 20px 10px 0;
}
p{
    font-size: 1.1rem;
    color: #737373;
}
.subTitle{
    font-size: 1.125rem;
    color: #003380;
    margin-bottom: 1rem;
}
.listItem{
    list-style: none;
}
@media (max-width: 1024px) {
    .LeftList,
    .RightList{
        margin: 0 0 10px 0;
    }
}
