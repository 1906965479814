.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.375rem;
  color: #003380;
  padding: 1rem;
}

.iconBackground {
  width: 28px;
  height: 28px; 
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5ccb5f;
  border-radius: 50%;
}

.icon {
  color: #fff;
  font-size: 1.1rem;
}
