.carousel {
  margin: auto;
  background: none;
  .img{
    width: 100%;
    height: auto;
    border-radius: 12px;
  }
  a[role="button"] {
    max-width: 5% !important;
    span {
      height: 24px;
    }
  } 
}
