.container{
    display: flex;
    padding: 0px !important;
}
.subTitle{
    font-size: 1.125rem;
    color: #003380;
}
p{
    display: inline-block;
    text-overflow: ellipsis !important;
    font-size: 1.1rem;
    color: #737373;
    text-align: justify;
}
@media (max-width: 1024px) {
    .container{
        flex-direction: column;
    }
}
@media (max-width: 768px) {
    .container{
        flex-direction: column;
    }
}
